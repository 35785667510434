import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';
import DefaultRedirect from '../routes/DefaultRedirect';
import RouteUpdater from '../routes/RouteUpdater';

import PageLayout from './PageLayout';
import LoginButton from '../features/loginScreen/LoginButton';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics';
import { useEffect } from 'react';

// NOTE: don't change that height value as this is checked by the UIKIT to
// have a different styling for the new menu than for the old menu.
const DEFAULT_HEIGHT = '750px';

const AppLayout = () => {
    useGoogleAnalytics();
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    const accessToken = useAppSelector(getAccessToken);

    // Because the root div's default height is necessary for the initial loading to prevent frequent changes
    // in the menu dropdown's height, we need to remove this minimum height if the user is not logged and
    // therefore lacks an access token. This ensures that the open marketplace does not adopt that specific height.
    useEffect(() => {
        const root = document.getElementById('root');
        if (!accessToken && root?.style.minHeight) {
            root.style.removeProperty('min-height');
        }
    }, [accessToken]);

    if (!displayMessages) {
        return null;
    }

    const styles = accessToken ? { height: DEFAULT_HEIGHT, minHeight: '400px' } : {};

    return (
        <ApplicationLayout>
            <div style={styles}>
                <IntlProvider
                    defaultLocale={DEFAULT_LOCALE}
                    key={userLocale}
                    locale={userLocale}
                    messages={displayMessages}
                >
                    {accessToken ? <PageLayout /> : <LoginButton />}
                    <RouteUpdater />
                    <DefaultRedirect />
                </IntlProvider>
            </div>
        </ApplicationLayout>
    );
};

export default AppLayout;
