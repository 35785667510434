import { useMemo } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { useAppSelector } from '../configuration/setup/hooks';
import { getTenant, Tenant } from '../configuration/tokenHandling/tokenSlice';
import { menuEntries, type MenuEntry } from '../data/menuEntries';
import { type ServiceCategories, useData } from './useData';
import { ServicesCategoriesList } from '../data/serviceData';

const byTenant = (tenant: Tenant) => (entry: MenuEntry) => entry.tenants.includes(tenant);

// Check if there are service items for this category.
// In case there are none, hide the menu entry
const byServices = (categories: ServiceCategories) => (entry: MenuEntry) => {
    if (ServicesCategoriesList.includes(entry.id)) {
        return !isEmpty(categories[entry.id]);
    }
    return true;
};

export const useMenu = () => {
    const tenant = useAppSelector(getTenant);
    const { dataCategories } = useData();

    return useMemo(() => {
        const items = tenant ? menuEntries.filter(byTenant(tenant)) : menuEntries;
        return items.filter(byServices(dataCategories)).filter((entry) => entry.visible);
    }, [dataCategories, menuEntries, tenant]);
};
