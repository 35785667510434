import { lazy } from 'react';
import classNames from 'classnames';

import { ErrorBoundary } from '../components/ErrorBoundary';
import SuspendedWithSpinner from '../components/SuspendedWithSpinner';
import { usePageCache } from '../hooks/usePageCache';
import { usePage } from '../hooks/usePage';
import { Category } from '../data/serviceData';

const Services = lazy(() => import('../pages/Services'));
const News = lazy(() => import('../pages/News'));
const EarlyAccess = lazy(() => import('../pages/EarlyAccess'));
const Exceptions = lazy(() => import('../pages/Exceptions'));
const Administration = lazy(() => import('../pages/Administration'));
const Marketplace = lazy(() => import('../pages/Marketplace'));
const Customercenter = lazy(() => import('../pages/Customercenter'));
const Settings = lazy(() => import('../pages/Settings'));
const Support = lazy(() => import('../pages/Support'));

// Once a page is loaded, keep it in the DOM and move it in the offscreen.
// This way a once loaded iframe needs to be initialized and log into only once
const getWrapperClassName = (currentPage: string, pageId: string) =>
    classNames(currentPage !== pageId ? 'position-offscreen' : 'display-flex min-height-100pct');

const PageLoader = () => {
    const currentPage = usePage();

    // Don't load all pages at once, cache them once they have been accessed
    const { isCached } = usePageCache(currentPage);

    return (
        <ErrorBoundary>
            <SuspendedWithSpinner>
                {isCached(Category.SERVICES) && (
                    <div className={getWrapperClassName(currentPage, Category.SERVICES)}>
                        <Services />
                    </div>
                )}
                {isCached(Category.MARKETPLACE) && (
                    <div className={getWrapperClassName(currentPage, Category.MARKETPLACE)}>
                        <Marketplace />
                    </div>
                )}
                {isCached(Category.CUSTOMERCENTER) && (
                    <div className={getWrapperClassName(currentPage, Category.CUSTOMERCENTER)}>
                        <Customercenter />
                    </div>
                )}
                {isCached(Category.NEWS) && (
                    <div className={getWrapperClassName(currentPage, Category.NEWS)}>
                        <News />
                    </div>
                )}
                {isCached(Category.EARLY_ACCESS) && (
                    <div className={getWrapperClassName(currentPage, Category.EARLY_ACCESS)}>
                        <EarlyAccess />
                    </div>
                )}
                {isCached(Category.EXCEPTIONS) && (
                    <div className={getWrapperClassName(currentPage, Category.EXCEPTIONS)}>
                        <Exceptions />
                    </div>
                )}
                {isCached(Category.ADMINISTRATION) && (
                    <div className={getWrapperClassName(currentPage, Category.ADMINISTRATION)}>
                        <Administration />
                    </div>
                )}
                {isCached(Category.SETTINGS) && (
                    <div className={getWrapperClassName(currentPage, Category.SETTINGS)}>
                        <Settings />
                    </div>
                )}
                {isCached(Category.SUPPORT) && (
                    <div className={getWrapperClassName(currentPage, Category.SUPPORT)}>
                        <Support />
                    </div>
                )}
            </SuspendedWithSpinner>
        </ErrorBoundary>
    );
};

export default PageLoader;
