import { useAppSelector } from '../configuration/setup/hooks';
import { getUserProfile } from '../configuration/login/loginSlice';
import { useEffect } from 'react';

let isFirstCall = true;

export function useGoogleAnalytics() {
    const userProfile = useAppSelector(getUserProfile);
    useEffect(() => {
        if (userProfile && isFirstCall) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'application_menu_loaded',
                account_id: userProfile.account,
                tenant: userProfile.tenant,
                trigger: 'visibility',
            });
            isFirstCall = false;
        }
    }, [userProfile]);
}
