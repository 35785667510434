import { useEffect, useState } from 'react';

export const usePageCache = (pageId: string) => {
    const [cachedPages, setCachedPages] = useState<string[]>([]);

    useEffect(() => {
        if (pageId) {
            const cache = new Set(cachedPages);
            setCachedPages([...cache.add(pageId)]);
        }
    }, [pageId]);

    return { cachedPages, isCached: (id: string) => cachedPages.includes(id) };
};
