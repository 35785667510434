import { trace } from './trace';
import * as storage from '../login/storage';
import { UserManager } from 'oidc-client-ts';

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = window.location.href.includes('redirected');
    try {
        const user = await userManager.signinSilent();
        const initialRoute = storage.routeStorage.getRoute();

        trace('initialRoute lookup', initialRoute);

        if (initialRoute && isFreshRedirect) {
            trace(`Go to location "/${initialRoute}"`);
            window.location.replace(`#/${initialRoute}`);
        }

        storage.routeStorage.discardRoute();
        return user;
    } catch (error) {
        trace('oidc.signinSilent failed', error);
    }
};
