import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import AppLayout from '../layout/AppLayout';
import PageLoader from '../layout/PageLoader';

export const DEFAULT_ROUTE = '/pages';

export const routes = [DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />}>
            <Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<PageLoader />} />
        </Route>
    )
);
