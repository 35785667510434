import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import useDarkMode from '@rio-cloud/rio-uikit/useDarkMode';

import { useData } from '../../hooks/useData';
import { getTrackingAttributes } from '../../configuration/setup/googleAnalytics';


const QuickstartNavigation = () => {
    const isDarkMode = useDarkMode();
    const { data } = useData();

    // Wait until the data is loaded to avoid flickering and stretching the items
    // in case the pages are not rendered
    if (!data) {
        return null;
    }


    const quickstartClassName = 'display-flex justify-content-between gap-10';
    const quickstartIconClassName = 'aspect-ratio-1 width-40 bg-size-cover margin-x-15';
    const QuickstartCaptionClassName = classNames(
        'line-height-125rel',
        'margin-top-5',
        'text-color-darker',
        'text-size-12'
    );
    const quickstartTileClassName = classNames(
        'align-items-center',
        'cursor-pointer',
        'display-flex',
        'flex-column',
        'padding-bottom-10',
        'padding-top-15',
        'rounded',
        'text-center',
        'text-decoration-none',
        'width-50pct',
        'border',
        isDarkMode ? 'bg-lightest hover-border-color-highlight-lightest' : 'border-color-lighter hover-bg-lightest'
    );

    return (
        <div className={quickstartClassName}>
            <a
                className={quickstartTileClassName}
                href="https://home.rio.cloud/"
                target="_top"
                rel="noreferrer"
                {...getTrackingAttributes({ trigger: 'click', action: 'quickStartNavigatedTo::home' })}
            >
                <div
                    className={quickstartIconClassName}
                    style={{
                        backgroundImage: 'url("https://rio.cloud/fileadmin/Marketplace/AppIcons/Home.svg")',
                    }}
                />
                <div className={QuickstartCaptionClassName}>
                    <FormattedMessage id={'intl-msg:glossary.serviceName.home'} />
                </div>
            </a>
            <a
                className={quickstartTileClassName}
                href="https://livemonitor.rio.cloud/"
                target="_top"
                rel="noreferrer"
                {...getTrackingAttributes({ trigger: 'click', action: 'quickStartNavigatedTo::fleetmonitor' })}
            >
                <div
                    className={quickstartIconClassName}
                    style={{
                        backgroundImage:
                            'url("https://rio.cloud/fileadmin/Marketplace/AppIcons/FleetmonitorQuickstart.svg")',
                    }}
                />
                <div className={QuickstartCaptionClassName}>
                    <FormattedMessage id={'intl-msg:glossary.serviceName.fleetmonitor'} />
                </div>
            </a>
        </div>
    );
};

export default QuickstartNavigation;
