import { Category } from './serviceData';
import {
    Tenant,
    TENANT_RIO_BRAZIL_PROD,
    TENANT_RIO_BRAZIL_TEST,
    TENANT_RIO_EU_PROD,
    TENANT_RIO_EU_TEST,
    TENANT_RIO_SOUTHKOREA_PROD,
    TENANT_RIO_SOUTHKOREA_TEST,
} from '../configuration/tokenHandling/tokenSlice';

export type MenuEntry = {
    id: string;
    title: string;
    description: string;
    icon: string;
    default?: boolean;
    visible: boolean;
    tenants: Tenant[];
};

export const menuEntries: MenuEntry[] = [
    {
        id: Category.SERVICES,
        title: 'intl-msg:extendedMenu.label.myServices',
        description: 'intl-msg:extendedMenu.description.myServices',
        icon: 'desktop',
        default: true,
        visible: true,
        tenants: [
            TENANT_RIO_EU_PROD,
            TENANT_RIO_EU_TEST,
            TENANT_RIO_BRAZIL_PROD,
            TENANT_RIO_BRAZIL_TEST,
            TENANT_RIO_SOUTHKOREA_PROD,
            TENANT_RIO_SOUTHKOREA_TEST,
        ],
    },
    {
        id: Category.ADMINISTRATION,
        title: 'intl-msg:extendedMenu.label.administration',
        description: 'intl-msg:extendedMenu.description.administration',
        icon: 'cog',
        visible: true,
        tenants: [
            TENANT_RIO_EU_PROD,
            TENANT_RIO_EU_TEST,
            TENANT_RIO_BRAZIL_PROD,
            TENANT_RIO_BRAZIL_TEST,
            TENANT_RIO_SOUTHKOREA_PROD,
            TENANT_RIO_SOUTHKOREA_TEST,
        ],
    },
    {
        id: Category.MARKETPLACE,
        title: 'intl-msg:extendedMenu.label.marketplace',
        description: 'intl-msg:extendedMenu.description.marketplace',
        icon: 'shopping-cart',
        visible: true,
        tenants: [
            TENANT_RIO_EU_PROD,
            TENANT_RIO_EU_TEST,
            TENANT_RIO_BRAZIL_PROD,
            TENANT_RIO_BRAZIL_TEST,
            TENANT_RIO_SOUTHKOREA_PROD,
            TENANT_RIO_SOUTHKOREA_TEST,
        ],
    },
    {
        id: Category.CUSTOMERCENTER,
        title: 'intl-msg:extendedMenu.label.customercenter',
        description: 'intl-msg:extendedMenu.description.customercenter',
        icon: 'document',
        visible: true,
        tenants: [
            TENANT_RIO_EU_PROD,
            TENANT_RIO_EU_TEST,
            TENANT_RIO_BRAZIL_PROD,
            TENANT_RIO_BRAZIL_TEST,
            TENANT_RIO_SOUTHKOREA_PROD,
            TENANT_RIO_SOUTHKOREA_TEST,
        ],
    },
    {
        id: Category.EARLY_ACCESS,
        title: 'intl-msg:extendedMenu.label.earlyAccess',
        description: 'intl-msg:extendedMenu.description.earlyAccess',
        icon: 'key',
        visible: false, // Hide because no cms content is available
        tenants: [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST],
    },
    {
        id: Category.EXCEPTIONS,
        title: 'intl-msg:extendedMenu.label.exceptions',
        description: 'intl-msg:extendedMenu.description.exceptions',
        icon: 'notification',
        visible: true,
        tenants: [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST, TENANT_RIO_BRAZIL_PROD, TENANT_RIO_BRAZIL_TEST],
    },
    {
        id: Category.NEWS,
        title: 'intl-msg:extendedMenu.label.news',
        description: 'intl-msg:extendedMenu.description.news',
        icon: 'info-sign',
        visible: true,
        tenants: [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST],
    },
    // {
    //     id: Category.SETTINGS,
    //     title: 'intl-msg:extendedMenu.label.profile',
    //     description: 'intl-msg:extendedMenu.description.profile',
    //     icon: 'user-sign',
    //     visible: true,
    //     tenants: [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST, TENANT_RIO_BRAZIL_PROD],
    // },
    {
        id: Category.SUPPORT,
        title: 'intl-msg:extendedMenu.label.support',
        description: 'intl-msg:extendedMenu.description.support',
        icon: 'support',
        visible: true,
        tenants: [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST],
    },
];
