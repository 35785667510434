import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import groupBy from 'lodash/fp/groupBy';

import { getLocale } from '../configuration/lang/langSlice';
import { useAppSelector } from '../configuration/setup/hooks';
import { useFetchAppsQuery } from '../services/appsApi';
import { Category, ServiceItem, type GroupedItems } from '../data/serviceData';

export type ServiceCategories = Record<string, ServiceItem[]>;

export const useData = () => {
    const locale = useAppSelector(getLocale);
    const { data, error, isLoading } = useFetchAppsQuery(locale);

    const intl = useIntl();

    // Translate service name and description to allow sorting by name in
    // their respective category
    const translatedData = useMemo(() => {
        return data?.map((item) => ({
            ...item,
            title: intl.formatMessage({ id: item.title }),
            description: intl.formatMessage({ id: item.description }),
            sortId: item.sortId,
            link: typeof item.link === 'function' ? item.link(intl) : item.link,
        }));
    }, [data]);

    // Group items by category for service pages
    const groupedItems = useMemo(() => {
        return groupBy('category')(translatedData) as unknown as GroupedItems;
    }, [translatedData]);

    return {
        data: translatedData,
        dataCategories: {
            [Category.SERVICES]: groupedItems[Category.SERVICES],
            [Category.EXCEPTIONS]: groupedItems[Category.EXCEPTIONS],
            [Category.ADMINISTRATION]: groupedItems[Category.ADMINISTRATION],
            [Category.MARKETPLACE]: groupedItems[Category.MARKETPLACE],
            [Category.CUSTOMERCENTER]: groupedItems[Category.CUSTOMERCENTER],
        } as ServiceCategories,
        error: error && (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
                fullWidth
            />
        ),
        loader: isLoading && (
            <div className="height-100vh display-flex align-items-center">
                <Spinner isDoubleSized />
            </div>
        ),
    };
};
