import { createContext, useContext } from 'react';

export type AppContextType = {
    isMobile: boolean;
};

const defaultAppContext = {
    isMobile: false,
};

export const AppContext = createContext(defaultAppContext);

export const useAppContext = () => {
    return useContext(AppContext);
};
