import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { getTrackingAttributes } from '../../configuration/setup/googleAnalytics';

type NavigationItemProps = {
    id: string;
    title: string;
    description: string;
    icon: string;
    infoIconIndicator?: boolean;
    infoIconIndicatorColor?: string;
};

const NavigationItem = (props: NavigationItemProps) => {
    const { id, title, description, icon, infoIconIndicator, infoIconIndicatorColor } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const isActive = searchParams.get('id') === id;

    // Handle warning state in the future
    const warning = false;

    const itemClassName = classNames('menu-item', isActive && 'active');

    const warningClassName = classNames(
        'badge',
        'badge-warning',
        'badge-indicator-border',
        'position-absolute',
        'top-0',
        'left-0',
        'translate-x-50pct-y-50pct',
        'pointer-events-none',
        'user-select-none'
    );

    const infoIconIndicatorClassName = classNames(
        'rioglyph',
        'rioglyph-info-sign',
        'text-size-20',
        'position-absolute',
        'right-0',
        'top-50pct',
        'translate-x-50pct-y-50pct',
        'pointer-events-none',
        infoIconIndicatorColor && `text-color-${infoIconIndicatorColor}`
    );

    const handleClick = () => {
        setSearchParams({ ...searchParams, id });
    };

    return (
        <li
            onClick={handleClick}
            className={itemClassName}
            data-testid={id}
            {...getTrackingAttributes({ trigger: 'click', action: `mainNavigation::${id}` })}
        >
            <div className="padding-top-3 padding-bottom-5">
                <div className="flex-1-1 display-flex align-items-center gap-10 padding-y-2">
                    {infoIconIndicator && !isActive && <span className={infoIconIndicatorClassName} />}
                    <div className="position-relative">
                        {warning && <div className={warningClassName} />}
                        <span className={`text-size-h4 text-color-primary rioglyph rioglyph-${icon}`} />
                    </div>
                    <div className="line-height-125rel">
                        <div className="text-size-16">
                            <FormattedMessage id={title} />
                        </div>
                        <div className="text-size-12 text-color-dark">
                            <FormattedMessage id={description} />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default NavigationItem;
