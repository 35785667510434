import { useState } from 'react';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { THEME_DARK, THEME_LIGHT, THEME_SYSTEM } from '@rio-cloud/rio-uikit/utils/colorScheme';
import { getColorScheme, setColorScheme } from '@rio-cloud/rio-uikit/ColorScheme';
import { FormattedMessage } from 'react-intl';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';


const getRadioClasses = (isSelected: boolean) =>
    `padding-10 border1 rounded ${isSelected ? 'bg-highlight-decent' : 'hover-bg-lightest'}`;

const DarkModeSwitch = () => {
    const [activeTheme, setActiveTheme] = useState(getColorScheme());

    const handleSetMode = (mode: 'dark' | 'light' | 'default' | 'system') => {
        // const val = getColorScheme();
        setActiveTheme(mode);
        setColorScheme(mode);

        // Send it to parent and child iframes so the get notified about the change
        window.parent.postMessage('themechange', '*');

        // Send it to the child iframes like news page or early access page
        // so the get notified about the change and change the mode too
        [...document.getElementsByTagName('iframe')].map((frame) =>
            frame.contentWindow?.postMessage('themechange', '*')
        );

        gaPush({
            category: TRACKING_CATEGORIES.DEFAULT,
            action: `activatedTheme::${mode}`,
            label: ''
        });
    };

    const content = (
        <div className="display-flex flex-column gap-5 padding-10">
            <RadioButton
                name="radioBox"
                custom
                className={getRadioClasses(activeTheme === THEME_LIGHT)}
                onChange={() => handleSetMode(THEME_LIGHT)}
                checked={activeTheme === THEME_LIGHT}
            >
                <div className="radio-text-wrapper display-flex">
                    <div className="radio-text" />
                    <div className="margin-left-5 margin-right-5">
                        <div className="text-medium">
                            <FormattedMessage id="intl-msg:extendedMenu.colorScheme.light" />
                        </div>
                        <div className="text-color-dark text-size-12">
                            <FormattedMessage id="intl-msg:extendedMenu.colorScheme.light.description" />
                        </div>
                    </div>
                </div>
            </RadioButton>
            <RadioButton
                name="radioBox"
                custom
                className={getRadioClasses(activeTheme === THEME_DARK)}
                onChange={() => handleSetMode(THEME_DARK)}
                checked={activeTheme === THEME_DARK}
            >
                <div className="radio-text-wrapper display-flex">
                    <div className="radio-text" />
                    <div className="margin-left-5 margin-right-5">
                        <div className="text-medium">
                            <FormattedMessage id="intl-msg:extendedMenu.colorScheme.dark" />
                        </div>
                        <div className="text-color-dark text-size-12">
                            <FormattedMessage id="intl-msg:extendedMenu.colorScheme.dark.description" />
                        </div>
                    </div>
                </div>
            </RadioButton>
            {/* <RadioButton
                name="radioBox"
                custom
                className={getRadioClasses(activeTheme === THEME_SYSTEM)}
                onChange={() => handleSetMode(THEME_SYSTEM)}
                checked={activeTheme === THEME_SYSTEM}
            >
                <div className="radio-text-wrapper display-flex">
                    <div className="radio-text" />
                    <div className="margin-left-5 margin-right-5">
                        <div className="text-medium">
                            <FormattedMessage id="intl-msg:extendedMenu.colorScheme.system" />
                        </div>
                        <div className="text-color-dark text-size-12">
                            <FormattedMessage id="intl-msg:extendedMenu.colorScheme.system.description" />
                        </div>
                    </div>
                </div>
            </RadioButton> */}
        </div>
    );

    return (
        <ButtonDropdown
            title={
                <div>
                    <span
                        className="rioglyph rioglyph-mode-dark-light margin-right-5 text-size-14"
                        aria-hidden="true"
                    />
                    {/* Farbwelt, Farbschema, Farbmodus, Darstellung */}
                    <FormattedMessage id="intl-msg:extendedMenu.colorScheme.label" />
                </div>
            }
            customDropdown={content}
            bsStyle="link"
            toggleClassName="height-auto padding-y-0"
            dropdownClassName="min-width-300"
            noCaret
            popperConfig={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [-70, 8],
                        },
                    },
                ],
            }}
            items={[]}
        />
    );
};

export default DarkModeSwitch;
